import ErrorBoundary from './components/ErrorBoundary';
import { useAnalyticsInitialize } from './hooks/useAnalyticsInitialize';
import Auth0Wrapper from './components/Auth0Wrapper';
import MiddlewareWrapper from './components/MiddlewareWrapper/MiddlewareWrapper';
import { Router } from './Router';

import 'focus-visible';

const App = function App() {
  useAnalyticsInitialize();

  return (
    <ErrorBoundary>
      <MiddlewareWrapper>
        <Auth0Wrapper>
          <Router />
        </Auth0Wrapper>
      </MiddlewareWrapper>
    </ErrorBoundary>
  );
};

export default App;
